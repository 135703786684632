<template>
  <div class="flex_column_evenly">

    <!-- DPI级数 -->
    <div class="flex_row" style="align-items: center;">
      <i class="incfont el-icon-title"></i>
      <span class="margin_left" lang="DPISetting">DPI级数</span>
      <select 
      v-model="maxDpiStage"
      @change="handleDpiStageChange"
      class="margin_left">
        <option             
        v-for="item in defaultMaxDpi" 
        :key="item" 
        :value="item">
        {{item}}
        </option>
      </select>
    </div>

    <!-- DPI滑条 -->
    <div class="flex_row">
      <el-slider 
      v-model="dpiValue"
      @change="handleDPIValueChange"
      :show-tooltip="false"
      :min="min"
      :max="max"
      :step="step"
      class="dpi_slider_width"></el-slider>
      <el-input-number
      v-model="dpiValue"
      @change="handleDPIValueChange"
      :min="min"
      :max="max"
      :step="inputStep"
      size="mini"
      class="margin_left">
      </el-input-number>   
    </div>


    <!-- DPI按钮 -->
    <div class="flex_row align_center">
      <div
      v-for="(value,index) in dpiParams"
      :key="index"
      class="flex_column align_center">
        <div 
          @click="handleDPIClick(index)"
          class="color-picker"
          :class="[currentDpi == index ? 'dpi_select_width' : 'dpi_unselect_width',index > 0?'dpi_margin_left' : '']" 
          style="justify-content: space-between;">
          <span 
          class="flex_center"
          :class="currentDpi == index ? 'dpi_select_width' : 'dpi_unselect_width'">{{value.value}}</span>
          <el-color-picker 
          v-show="showDpiCOlor"
          v-model="value.color"
          @change="handleColorChange(index,value.color)"
          color-format="rgb"></el-color-picker>
        </div>
        <div class="triangle" :class="index > 0?'dpi_margin_left' : ''" v-show="currentDpi == index"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import HIDHandle from '@/assets/js/HIDHandle';
  
  export default {
    data() {
      return {
        maxDpiStage:5,
        lastMaxDpi:5,
        defaultMaxDpi:8,
        dpiValue:3500,
        lastDpiValue:3500,
        min:50,
        max:30000,
        step:50,
        inputStep:50,
        currentDpi:3,
        showDpiCOlor:true,
        dpiParams:[
          {
            value:400,
            color:'rgb(255,0,0)'
          },
          {
            value:800,
            color:'rgb(0,255,0)'
          },
          {
            value:2400,
            color:'rgb(0,0,255)'
          },
          {
            value:4800,
            color:'rgb(255,255,0)'
          },
          {
            value:26000,
            color:'rgb(255,255,255)'
          },
        ],
        lastDPIParams:[],
        mouseDpiParams:[],
        sensor:{},
        sensorParam:{},
      }
    },
    methods: {
      async handleDpiStageChange() {
        if(await HIDHandle.Set_MS_MaxDPI(this.maxDpiStage)) {
          if(this.maxDpiStage < this.lastMaxDpi) {
            if(this.currentDpi >= (this.maxDpiStage - 1)) {
              var index = this.maxDpiStage - 1;

              var current = {
                value :this.dpiParams[this.currentDpi].value,
                color :this.dpiParams[this.currentDpi].color,
              };

              var last = {
                value :this.dpiParams[index].value,
                color :this.dpiParams[index].color,
              };

              this.dpiParams.splice(this.currentDpi,1,last);
              this.dpiParams.splice(index, 1 ,current);

              for(var i = 0;i < this.lastMaxDpi;i++) {
                this.mouseDpiParams[i] = this.dpiParams[i];
              }

              await HIDHandle.Set_MS_DPIColor(this.currentDpi, this.dpiParams[this.currentDpi].color);
              await HIDHandle.Set_MS_DPIValue(this.currentDpi, this.dpiParams[this.currentDpi].value);
              await HIDHandle.Set_MS_DPIColor(index, this.dpiParams[index].color);
              await HIDHandle.Set_MS_DPIValue(index, this.dpiParams[index].value);
              this.currentDpi = this.maxDpiStage - 1;
              await HIDHandle.Set_MS_CurrentDPI(this.currentDpi);
            }

            for(var i = this.lastMaxDpi - 1;i > this.maxDpiStage - 1;i--) {
              this.dpiParams.pop(i);
            }   
            
            this.lastDPIParams = this.dpiParams;
          }
          else {
            for(var i = this.lastMaxDpi;i < this.maxDpiStage;i++) {
              this.dpiParams.push(this.mouseDpiParams[i]);
            }
          }

          this.lastMaxDpi = this.maxDpiStage;
        }
        else {
          this.maxDpiStage = this.lastMaxDpi;
        }
      },
      async handleDPIValueChange() {

        var value = this.dpiValue;
        var dpi = this.dpiValue;
        var index = this.sensorParam.range.length - 1;
        for(index = this.sensorParam.range.length - 1;index >=0;index--) {
          var tmp = (this.sensorParam.range[index].min - this.sensorParam.range[index].step);
          if(dpi > tmp) {
            value = Math.ceil(value / this.sensorParam.range[index].step) * this.sensorParam.range[index].step;
            this.inputStep = this.sensorParam.range[index].step;
            break;
          }
        }

        if(await HIDHandle.Set_MS_DPIValue(this.currentDpi, value)) {
          this.lastDpiValue = this.dpiValue = value;
          this.lastDPIParams[this.currentDpi].value = this.dpiParams[this.currentDpi].value = this.dpiValue;
        }
        else {
          this.dpiValue = this.lastDpiValue;
        }
      },
      async handleDPIClick(index) {
        if(this.currentDpi != index) {
          if(await HIDHandle.Set_MS_CurrentDPI(index)) {
            this.currentDpi = index;

            var value = this.dpiValue;
            var index = this.sensorParam.range.length - 1;
            for(index = this.sensorParam.range.length - 1;index >=0;index--) {
              var tmp = (this.sensorParam.range[index].min - this.sensorParam.range[index].step);
              if(value > tmp) {
                this.inputStep = this.sensorParam.range[index].step;
                break;
              }
            }
          }
        }
      },
      async handleColorChange(index,color) {
        var online = true;
        if(this.currentDpi != index) {
          if(await HIDHandle.Set_MS_CurrentDPI(index)) {
            this.currentDpi = index;
          }
          else {
            online = false;
          }
        }

        if(online) {
          if(await HIDHandle.Set_MS_DPIColor(index,color)) {
            this.lastDPIParams[index].color = this.dpiParams[index].color = color;
          }
          else {
            this.dpiParams[index].color = this.lastDPIParams[index].color;
          }
        }
        else {
          this.dpiParams[index].color = this.lastDPIParams[index].color;
        }
      }
    },
    watch: {
      "currentDpi": {
        handler() {
          if(this.currentDpi < this.maxDpiStage)
            this.lastDpiValue = this.dpiValue = this.dpiParams[this.currentDpi].value;
        }
      }
    },
    mounted() {
      this.$bus.$on("setGlobalSensor",value => {
        this.sensor = value;
      });  

      this.$bus.$on("setMouseDefaultCfg",deviceCfg => {
        this.max = deviceCfg.maxDpi;
        this.lastMaxDpi = this.defaultMaxDpi = this.maxDpiStage = deviceCfg.dpis.length;
        this.lastDPIParams = [];
        this.dpiParams = [];
        for(var i = 0;i < deviceCfg.dpis.length;i++) {
          var param = {
            value:deviceCfg.dpis[i].value,
            color:deviceCfg.dpis[i].color
          };

          var param1 = {
            value:deviceCfg.dpis[i].value,
            color:deviceCfg.dpis[i].color
          };
          this.lastDPIParams.push(param1);
          this.dpiParams.push(param);
        } 
        
        this.currentDpi = deviceCfg.currentDpi;
        this.sensorParam = this.sensor[HIDHandle.deviceInfo.mouseCfg.sensor.type];
        this.min = this.sensorParam.range[0].min;
        if(this.max > this.sensorParam.range[this.sensorParam.range.length - 1].max) {
          this.max = this.sensorParam.range[this.sensorParam.range.length - 1].max;
        }

        this.step = this.sensorParam.range[0].step;
        
        if(deviceCfg.disableDpiColor == true) {
          this.showDpiCOlor = false;
        }
        else {
          this.showDpiCOlor = true;
        }
      }); 

      this.$bus.$on("updateMouseUI",value => {
        this.lastDPIParams = [];
        this.dpiParams = [];
        this.mouseDpiParams = [];
        for(var i = 0;i < 8;i++)
        {
          var param = {
            value:value.dpis[i].value,
            color:value.dpis[i].color,
          };

          var param1 = {
            value:value.dpis[i].value,
            color:value.dpis[i].color,
          };

          if(i < value.maxDpiStage) {
            this.lastDPIParams.push(param1);
            this.dpiParams.push(param);
          }

          this.mouseDpiParams.push(param);
        }

        this.currentDpi = value.currentDpi;
        this.lastMaxDpi = this.maxDpiStage = value.maxDpiStage;
        this.lastDpiValue = this.dpiValue = this.dpiParams[this.currentDpi].value;

        var value = this.dpiValue;
        var index = this.sensorParam.range.length - 1;
        for(index = this.sensorParam.range.length - 1;index >=0;index--) {
          var tmp = (this.sensorParam.range[index].min - this.sensorParam.range[index].step);
          if(value > tmp) {
            this.inputStep = this.sensorParam.range[index].step;
            break;
          }
        }
      });

      this.$bus.$on("updateCurrentDPI",value => {
        this.currentDpi = value;
      });  
    },
    beforeDestroy() {
      this.$bus.$off("setMouseDefaultCfg");
      this.$bus.$off("updateMouseUI");
      this.$bus.$off("updateCurrentDPI");
    },
  }
</script>

<style lang="less" scoped>
.dpi_margin_left {
  margin-left: 5vw;
}
</style>